import React, { useEffect, useRef } from "react";
import "./ComingSoon.css";

const ComingSoon: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current!;
    const ctx = canvas.getContext("2d")!;
    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    interface Node {
      x: number;
      y: number;
      speedX: number;
      speedY: number;
      connections: Node[];
    }

    let nodes: Node[] = [];
    const numberOfNodes = 50;
    const connectionDistance = 150;
    const nodeRadius = 2;

    function initNodes() {
      nodes = [];
      for (let i = 0; i < numberOfNodes; i++) {
        nodes.push({
          x: Math.random() * canvas.width,
          y: Math.random() * canvas.height,
          speedX: (Math.random() - 0.5) * 0.5,
          speedY: (Math.random() - 0.5) * 0.5,
          connections: []
        });
      }
    }

    function drawNodes() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      ctx.strokeStyle = 'rgba(169, 201, 255, 0.15)';
      ctx.lineWidth = 1;
      
      nodes.forEach(node => {
        node.connections = [];
        nodes.forEach(otherNode => {
          if (node === otherNode) return;
          
          const dx = node.x - otherNode.x;
          const dy = node.y - otherNode.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < connectionDistance) {
            node.connections.push(otherNode);
            ctx.beginPath();
            ctx.moveTo(node.x, node.y);
            ctx.lineTo(otherNode.x, otherNode.y);
            ctx.stroke();
          }
        });
      });

      ctx.fillStyle = '#a9c9ff';
      nodes.forEach(node => {
        ctx.beginPath();
        ctx.arc(node.x, node.y, nodeRadius, 0, Math.PI * 2);
        ctx.fill();
      });
    }

    function updateNodes() {
      nodes.forEach(node => {
        node.x += node.speedX;
        node.y += node.speedY;

        if (node.x < 0 || node.x > canvas.width) node.speedX *= -1;
        if (node.y < 0 || node.y > canvas.height) node.speedY *= -1;
      });
    }

    function animate() {
      updateNodes();
      drawNodes();
      requestAnimationFrame(animate);
    }

    initNodes();
    animate();

    const resizeListener = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
      initNodes();
    };

    window.addEventListener("resize", resizeListener);
    return () => window.removeEventListener("resize", resizeListener);
  }, []);

  return (
    <div className="coming-soon">
      <canvas ref={canvasRef} id="background"></canvas>
      <div className="content">
        <h1>API of Everything</h1>
        <p>Complete API Management & Monitoring Solution</p>
        <div className="coming-soon-badge">Coming Soon</div>
        
        <div className="features">
          <div className="feature-item">
            <h3>Usage Analytics</h3>
            <p>Detailed metrics, response times, and real-time monitoring for all your API endpoints</p>
          </div>
          
          <div className="feature-item">
            <h3>Rate Limiting</h3>
            <p>Flexible daily and monthly request limits with automatic quota management</p>
          </div>
          
          <div className="feature-item">
            <h3>Error Tracking</h3>
            <p>Comprehensive error logging and statistics for quick debugging</p>
          </div>
          
          <div className="feature-item">
            <h3>Access Control</h3>
            <p>API key management with endpoint-specific permissions and expiration dates</p>
          </div>

          <div className="feature-item">
            <h3>Usage Plans</h3>
            <p>Customizable packages with different request limits and features</p>
          </div>
          
          <div className="feature-item">
            <h3>Performance Metrics</h3>
            <p>Track latency, success rates, and status codes across all endpoints</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
